import React from 'react';
import { useLocation } from 'react-router-dom';

const SearchResultsPage = () => {
    const location = useLocation();
    const { searchResults } = location.state || {};

    return (
        <div>
        <h3>Results</h3>
        {/*<div>
            <h2>Search Results</h2>
            {searchResults && searchResults.length > 0 ? (
                searchResults.map((result, index) => (
                    <div key={index}>
                        <h3>{result.name}</h3>
                        <p>{result.type}</p>
                    </div>
                ))
            ) : (
                <p>No results found.</p>
            )}
        </div>*/}
        </div>
    );
}

export default SearchResultsPage;
