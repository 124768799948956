import React from "react";
import { Row, Col, Container } from "react-bootstrap";

//custom sidebar component
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import '../styles/Scrollbar.css';

const CartCardDeck = ({heading, children }) => {
    return (
        <Container className='mt-1' >
            <Row>
            <Col xs={12} md={6} lg={5}>
                {<h3>{heading}</h3>}
            </Col>
            </Row>
            <SimpleBar style={{ maxHeight: "75vh", overflowX: "hidden" }}>
                <Row className="row-cols-1">
                    {children}
                </Row>
            </SimpleBar>
        </Container>
    )
}

export default CartCardDeck;