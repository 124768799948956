import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import item1 from "../components/images/order1.svg";
import item2 from "../components/images/order2.svg";
import item3 from "../components/images/order3.svg";

import "../styles/HowitWorks.css";
const HowitWorks = () => {
  return (
    <div className="section-background">
      <Container className="d-flex justify-content-center">
        <Row className="">
          <Col md={4} className="d-flex flex-column align-items-center">
            <img src={item1} alt="Item 1" className="item-image" />
            <h2>Claim offer</h2>
            <p className="item-text">
              Press the claim button to redeem this coupon. Make sure you are at
              the store while you press claim.
            </p>
          </Col>
          <Col md={4} className="d-flex flex-column align-items-center">
            <img src={item2} alt="Item 2" className="item-image" />
            <h2>Visit the counter</h2>
            <p className="item-text">
              Show the counter staff the claimed coupon page. This page will be
              only available for limited time once you press 'claim now'.
            </p>
          </Col>
          <Col md={4} className="d-flex flex-column align-items-center">
            <img src={item3} alt="Item 3" className="item-image" />
            <h2>Get it done</h2>
            <p className="item-text">
            The store will reduce the you save* amount of this coupon from your bill.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HowitWorks;
