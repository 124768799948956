import React, { useEffect, useState } from 'react';
import axios from 'axios';
import WalletCardDeck from '../components/WalletCardDeck';
import WalletCard from '../components/WalletCard';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { useAuth } from "../provider/authProvider";
import useLocation from '../utils/useLocation';
import "../styles/WalletPage.css";

function WalletPage() {
    // URL based on run environment
    const apiUrl = process.env.REACT_APP_apiUrl;

    const heading = "Your Wallet";
    const [coupons, setCoupons] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const { token } = useAuth(); // to get email
    const email = token;
    const { errorMessage: locErrorMessage } = useLocation(apiUrl, email);

    useEffect(() => {
        if (locErrorMessage) {
            setErrorMessage(locErrorMessage);
            return;
        }

        const url = `${apiUrl}/GetWalletDetails?email=${encodeURIComponent(email)}`;

        axios.get(url)
            .then(response => {
                setCoupons(response.data);
                setErrorMessage(''); // Clear any previous error messages
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                if (error.response) {
                    if (error.response.status === 401) {
                        setErrorMessage('Customer details not found or email mismatch.');
                    } else if (error.response.status === 404) {
                        setErrorMessage('Merchant or Coupon Details not found.');
                    } else if (error.response.status === 204) {
                        setCoupons([]); // No content, set empty array
                        setErrorMessage(''); // Clear any previous error messages
                    } else if (error.response.status === 500) {
                        setErrorMessage('Unexpected error while fetching wallet details.');
                    } else {
                        setErrorMessage('Network response was not ok');
                    }
                } else {
                    setErrorMessage(error.message);
                }
            });
    }, [email, apiUrl, locErrorMessage]);

    return (
      <div>
          <Container className="mx-auto wallet-details-wrap">
            <Row className="align-items-top mb-5">
                <Col className="p-0">
                    {errorMessage ? (
                        <Alert variant="danger">{errorMessage}</Alert>
                    ) : (
                        <>
                            {coupons.length === 0 ? (
                                <Alert variant="info">You have 0 Coupons, please visit a store to add more.</Alert>
                            ) : (
                                <WalletCardDeck heading={heading}>
                                    {coupons.map(coupon => (
                                        <WalletCard key={coupon.couponId} coupon={coupon} />
                                    ))}
                                </WalletCardDeck>
                            )}
                        </>
                    )}
                </Col>
            </Row>
          </Container>
        </div>
    );
}

export default WalletPage;
