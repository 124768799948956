import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCouponUrl } from "../utils/HelperFunctions";
import '../styles/CoupenCard.css';
import dummyimg from "../components/images/temp/biriyaniSample.jpg"
import location from "../components/images/location.svg"
import restaurent from "../components/images/rest-icon.svg"
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const CouponCard = ({ coupon }) => {
    // URL based on run environment
    const apiUrl = process.env.REACT_APP_apiUrl;

    const [imageUrl, setImageUrl] = useState(dummyimg); // Initial image as fallback
    const [isLoading, setIsLoading] = useState(true);

    /**
     * Fetches the presigned URL for the image associated with the coupon.
     */
    const fetchImage = async (objectKey) => {
        try {
        const response = await axios.get(
            `${apiUrl}/GeneratePresignedUrl`,
            {
            params: { objectKey },
            }
        );
        setImageUrl(response.data); // Set the fetched image URL
        } catch (error) {
        console.error("Error fetching image:", error);
        setImageUrl(dummyimg); // Fallback in case of an error
        } finally {
        setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchImage("CouponImages/"+coupon.merchantID+"/"+coupon.imgName); // Trigger the image fetch on component mount
    }, [coupon.couponID]);
        

    return (
        <div  className=" mb-3 coupen-card-item">
            <Link to={`/${getCouponUrl(coupon.couponID)}`} style={{ textDecoration: "none", color: "inherit" }}> 
                <div className="card ">
                    <div className="">
                        <div className="">
                            <img src={imageUrl} className="coupen_image" height="144px" alt=""/>
                        </div>
                        <div className="">
                            <div className="card-inner">
                            <div className="restaurant-name">
                                <span><img src={restaurent} alt="restaurant" className="rest-icon-img-new"/> </span>
                                {coupon.merchantName}</div>
                        
                                <h5 className="card-title item-name">{coupon.couponName}</h5>
                                {/* <p className="card-text mt-0"><small className="text-muted ">*In-Store {coupon.instorePrice}</small></p>
                                <p className="card-text mt-1 mb-0 font-weight-bold" style={{ color: "#e80d0d" }}>NOW {coupon.now}</p> */}

                                <p className="coupen-rate-now" style={{ color: "#e80d0d" }}>
                                <span className="Now-p-tex">NOW</span> ${coupon.offerPrice}
                                {/* <sup className="previous-prize">
                                In-Store ${coupon.instorePrice}
                                </sup> */}
                                </p>
                                    <p className="previous-prize new-previous-prize">
                                
                                In-Store <span className="c-instore-p"> ${coupon.inStorePrice}</span>
                            
                                    </p>
                                <div className="d-flex expiry-main">

                                    <p className="you-save"><small className="text" style={{ color: "#007aff" }}>Save {coupon.inStorePrice - coupon.offerPrice}</small></p>
                                    <p className="expiry-text">Valid till: {coupon.validTill}</p>
                                </div>
                                <div className="location"><span><img src={location} alt="location" /> </span>{coupon.distanceToMerchant}</div>
                                <div className="claim-now" style={{ background: "#e80d0d", color: "#ffffff", borderColor: "#e80d0d" }}>Get Coupon</div>                    
                            </div>
                        </div>
                    </div>
                </div>
            </Link> 
        </div>


    )
}


export default CouponCard;

