import React from "react";
import { Row, Col, Container } from "react-bootstrap";

//increasing the number of coupons per row in mobile view is not ideal due to number of fields to be displayed
const ResponsiveCardDeck = ({heading, children }) => {
    return (
        <Container className='common-padding'>
            <Row>
            <Col xs={12} md={6}>
                {<h3>{heading}</h3>}
            </Col>
            </Row>
            <Row className="row">  
                {children}
            </Row>
        </Container>
    )
}

export default ResponsiveCardDeck;