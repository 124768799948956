// Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/Footer.css';
import logo from "./images/footer-logo-new.png"

const Footer = () => {
  return (
    <footer className="footer text-white">
    <div className="container">
      <div className="footer-content d-flex justify-content-between align-items-center">
        <div className="logo">
          <img src={logo} alt="Logo" className="footer-logo" />
        </div>
        <div className="copyright">
          &copy; {new Date().getFullYear()} CoupenBasket. All rights reserved.
        </div>
      </div>
    </div>
    </footer>
  );
}

export default Footer;
