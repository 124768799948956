import React from 'react';
import '../styles/Topdeals.css';
import tea from "./images/banner/tea.svg";
import burger from "./images/banner/burger.svg";
import biriyani from "./images/banner/biriyani.svg";
import pizza from "./images/banner/pizza.svg";
import sandwich from "./images/banner/sandwich.svg";
import Slider from "react-slick";

const TopDeals = () => {
  const settings = {
    infinite: true, 
    speed: 500, 
    slidesToShow: 4, // Show 4 images in a row on web
    slidesToScroll: 1, // Scroll one image at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, 
    arrows: false, // Disable arrows
    dots: false, // Disable dots
    responsive: [
      {
        breakpoint: 768, // At 768px or less (common mobile breakpoint)
        settings: {
          slidesToShow: 2, // Show 2 images in a row
          slidesToScroll: 2, // Scroll two images at a time
          rows: 2, // Show 2 rows
        },
      },
    ],
  };

  return (
    <div className="container top-deals">
      <h1>Top deals</h1>
      <div className="top-deals-container">
        <Slider {...settings}>
          <div className="deal-item">
            <img src={burger} alt="Deal 1" />
          </div>
          <div className="deal-item">
            <img src={tea} alt="Deal 2" />
          </div>
          <div className="deal-item">
            <img src={biriyani} alt="Deal 3" />
          </div>
          <div className="deal-item">
            <img src={pizza} alt="Deal 4" />
          </div>
          {/* <div className="deal-item">
            <img src={sandwich} alt="Deal 5" />
          </div> */}
        </Slider>
      </div>
    </div>
  );
};

export default TopDeals;
