import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { Card } from 'react-bootstrap';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../styles/AllMerchants.css';
import MerchantCard from "./MerchantCard";

const MerchantSlider = ({ merchants }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                }
            }
        ]
    };

    useEffect(() => {
        function setEqualHeight() {
            let maxHeight = 0;
            const cards = document.querySelectorAll('.merchant-card');
            
            cards.forEach(card => {
                card.style.height = 'auto'; // Reset height
                if (card.offsetHeight > maxHeight) {
                    maxHeight = card.offsetHeight;
                }
            });

            cards.forEach(card => {
                card.style.height = `${maxHeight}px`; // Set all cards to max height
            });
        }

        setEqualHeight();
        window.addEventListener('resize', setEqualHeight);

        return () => window.removeEventListener('resize', setEqualHeight);
    }, [merchants]);

    // Sorting merchants by a property, e.g., 'distance'
    const sortedMerchants = [...merchants].sort((a, b) => 
        b.couponCount - a.couponCount || a.distanceToMerchant.localeCompare(b.distanceToMerchant)
    );
    console.log("hi");

    return (
        <div className='container allmerch'>
            <h1 className="near-by-item-text all-rest-view-item">All Merchants <a href="#">View All</a> </h1>
            {sortedMerchants.length === 0 ? (
                <div className="no-merchants-message">No Merchants nearby</div>
            ) : (
                <Slider {...settings} className='slick-slider-container'>
                    {sortedMerchants.map((merchant, index) => (
                        <MerchantCard key={merchant.merchantID} merchant={merchant} index={index} />
                    ))}
                </Slider>
            )}
        </div>
    );
};

export default MerchantSlider;
