import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import logo from "./images/banner/coupen-logo-new.png";
import search from "./images/banner/search.svg";
import wallet from "./images/Wallet-icon.svg"
import '../styles/Navbar.css';

const AppNavbar = ({token}) => {
  const apiUrl = process.env.REACT_APP_apiUrl;
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [allCoupons, setAllCoupons] = useState([]);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true); // New state to manage the hamburger menu collapse
  const placeholders = ["Search merchants", "Find coupons", "Discover deals", "Explore new merchants"];
  const navigate = useNavigate();
  const navRef = useRef(null); // Ref to the nav element

  useEffect(() => {
    fetchAllCoupons();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [placeholders.length]);

  const fetchMerchantNames = async (str) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(apiUrl + `/Search?term=${str}`);
      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      const merchantNames = data.map(merchant => ({
        id: merchant.id,
        name: merchant.name,
        type: merchant.type
      }));
      setMerchants(merchantNames);
    } catch (error) {
      console.error('Error fetching merchants:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchAllCoupons = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(apiUrl + '/coupons');
      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = await response.json();
      setAllCoupons(data);
    } catch (error) {
      console.error('Error fetching coupons:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSelect = (selected) => {
    setSelectedMerchant(selected);
    if (selected) {
      navigate(`/${selected.name.replace(/\s+/g, '').toLowerCase()}`);
      setIsNavCollapsed(true); // Close the menu after selecting a merchant
    }
  }

  const handleSearchChange = (text) => {
    if (!text) {
      fetchAllCoupons();
      navigate('/allCoupons');
    } else {
      fetchMerchantNames(text);
    }
  }

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed); // Toggle the menu collapse

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsNavCollapsed(true); // Close the menu if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className='nav-mbl-wrapper' ref={navRef}>
      <nav className="navbar navbar-expand-xl navbar-light fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Logo" className='Nav-bar-logo-section' />
          </a>
          {/*<div className="search-icon">
             <div className="search-box-container">
              <form className="d-flex">
                <div className="input-group w-100">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <img src={search} alt="Search" width="20" height="20" />
                    </span>
                  </div>
                  <Typeahead
                    id="basic-typeahead"
                    labelKey="name"
                    className="form-control search-input"
                    options={merchants}
                    selected={selectedMerchant}
                    onChange={(selected) => handleSelect(selected[0])}
                    placeholder={placeholders[placeholderIndex]}
                    onInputChange={(text) => handleSearchChange(text)}
                  />
                  {isLoading && (
                    <div className="loading-indicator">Loading...</div>
                  )}
                </div>
              </form>
            </div> 
          </div>*/}
          <div className="ms-auto d-flex align-items-center">
            <Button className="py-0 mr-1 bg-transparent border-0 shadow-none wallet-icon-small"  as={Link} to="/Wallet">
              <img src={wallet} alt="View wallet" style={{ width: '40px', height: '40px' }} />
            </Button>
            <button
              className="navbar-toggler"
              type="button"
              onClick={handleNavCollapse} // Toggle the menu on click
              aria-controls="navbarNav"
              aria-expanded={!isNavCollapsed}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <Button className="py-0 mr-1 bg-transparent border-0 shadow-none wallet-icon-large"  as={Link} to="/Wallet">
                <img src={wallet} alt="View wallet" style={{ width: '40px', height: '40px' }} />
              </Button>
              {/*<li className="nav-item" onClick={handleNavCollapse}>
                <Link to="/" className="nav-link">Account</Link>
              </li>
              <li className="nav-item" onClick={handleNavCollapse}>
                <Link to="/" className="nav-link">Orders</Link>
              </li>   to be used later*/}
              {!token && (<li className="nav-item nav-item-row" onClick={handleNavCollapse}>
                <a className="btn btn-primary login" href="Login/">Log In</a>
              </li>)}
              {!token && (<li className="nav-item nav-item-row" onClick={handleNavCollapse}>
                <a className="btn btn-outline-primary signup" href="Signup/">Sign Up</a>
              </li>)}
              {token && (<li className="nav-item nav-item-row" onClick={handleNavCollapse}>
                <a className="btn btn-outline-primary signup" href="Signup/">Log Out</a>
              </li>)}
            </ul>
          </div>
        </div>
      </nav>

      <div className="search-icon">
            <div className="search-box-container">
              <form className="d-flex">
                <div className="input-group w-100">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <img src={search} alt="Search" width="20" height="20" />
                    </span>
                  </div>
                  <Typeahead
                    id="basic-typeahead"
                    labelKey="name"
                    className="form-control search-input"
                    options={merchants}
                    selected={selectedMerchant}
                    onChange={(selected) => handleSelect(selected[0])}
                    placeholder={placeholders[placeholderIndex]}
                    onInputChange={(text) => handleSearchChange(text)}
                  />
                  {isLoading && (
                    <div className="loading-indicator">Loading...</div>
                  )}
                </div>
              </form>
            </div>
          </div>
    </div>
  );
}

export default AppNavbar;
