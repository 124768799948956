import React, { useState } from "react";
import { Container, Row, Col, Button, Figure } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCouponUrl } from "../utils/HelperFunctions";
import trash from "./images/TrashCan.png"

const CouponCard = ({ coupon }) => {

    let [count, setCount] = useState(1);

    function incrementCount() {
        //add code to check if more coupons are available
        count = count + 1;
        setCount(count);
    }
    function decrementCount() {
        if(count>1)
            {
                //release coupon code from user cart
                count = count - 1;
                setCount(count);
            }
    }

    return (
        <Col className="mt-1">
            <div className="row no-gutters border rounded">
                <div className="col-auto">
                    <Link to={`/${getCouponUrl(coupon.itemName)}`} style={{ textDecoration: "none", color: "inherit" }}>
                        <img src={coupon.couponImage} className="object-fit-contain border rounded" height="170px" width="170px" alt=""/>
                    </Link>
                </div>
                <div className="col">
                    <Container className="card-block px-2">
                        <Row className="mx-0">
                            <h5 className="card-title mb-0">{coupon.itemName}</h5>
                        </Row>
                        <Row className="mx-0">
                            <p className="card-text mt-0"><small className="text-muted">*In-Store {coupon.instorePrice}</small></p>
                        </Row>
                        <Row className="mx-0">
                            <p className="card-text mt-1 mb-0 font-weight-bold" style={{ color: "#e80d0d" }}>NOW {coupon.now}</p>
                        </Row>
                        <Row className="mx-0">
                        <p className="card-text mt-0 mb-1"><small className="text" style={{ color: "#007aff" }}>*you save {coupon.save}</small></p>
                        </Row>
                        <Row className="d-flex justify-content-end mx-0 text-center align-items-center">
                            <Col className="pr-0" xs={'auto'} >
                                <div className="input-group">
                                    <span class="input-group-btn">
                                        <Button className="border-0 font-weight-bold" size="sm" style={{ height: "30px", width: "25px", background: '#000000', color: '#ffffff' }} 
                                        type="button" onClick={decrementCount}>-</Button>
                                    </span>

                                    <div className="px-2">{count}</div>

                                    <span class="input-group-btn">
                                        <Button className="border-0 font-weight-bold" size="sm" style={{ height: "30px",width: "25px", background: '#000000', color: '#ffffff' }} 
                                        type="button" onClick={incrementCount}>+</Button>
                                    </span>
                                </div>
                            </Col>
                            <Col ClassName="px-0" xs={'auto'}>
                                <Button className="border-0 px-0" style={{backgroundColor: "#ffffff"}} onClick={""}>
                                    <Figure className='my-auto'>
                                        <Figure.Image className='my-auto'
                                            height={35}
                                            width={35}
                                            src={trash}
                                        />
                                    </Figure>
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </Col>
    )
}


export default CouponCard;

