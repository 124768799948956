import React from "react";
import '../styles/CoupenCard.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CuponCard from "./CouponCard";

const CouponSlider = ({ coupons }) => {
  const settings = {
    arrows: true,
    slidesToShow: 4,
    infinite: true,
    swipeToSlide: true,
    speed: 300,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          adaptiveHeight: false,
        },
      },
    ],
  };

  return (
    <div className="container slider-item test-slider">
      <h1 className="near-by-item-text">Near By <a href="#">View All</a></h1>
      {coupons.length === 0 ? (
        <div className="no-coupons-message">No Coupons nearby</div>
      ) : (
        <Slider {...settings} className="pb-5">
          {coupons.map(coupon => (
            <CuponCard key={coupon.couponID} coupon={coupon} />
          ))}
        </Slider>
      )}
    </div>
  );
};

export default CouponSlider;
