import React from 'react';
import { useState } from 'react';
import { Container, Carousel, Row, Col, Button } from "react-bootstrap";
import '../styles/BannerCarousel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import banner2 from "./images/banner/banner3.jpg"
import banner3 from "./images/banner/banner2.jpg"
import Slider from "react-slick";
const HeaderBody = () => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false,
      
      };

    return (
        // <Container fluid className='d-flex justify-content-center align-items-center'>
        //     <Row className='mx-auto'>
        //         <Carousel className='mx-auto' fade activeIndex={index} onSelect={handleSelect} interval={15000} prevLabel={null} nextLabel={null}>
        //             <Carousel.Item>
        //                 <img  src={banner3} alt="First slide" 
        //                 style={{ maxHeight: "min(20vh, 400px)", minHeight: "max(20vh, 200px)", MaxWidth: "min(100vw,1200px)", minWidth: "min(100vw,1200px)" }}/>
        //                 <Carousel.Caption>
        //                     <h3 style={{ fontSize: "min(4vw, 20px)", color: '#000000' }}>Start saving with CouponBasket</h3>
        //                 </Carousel.Caption>
        //             </Carousel.Item>
        //             <Carousel.Item>
        //                 <img  src={banner2} alt="Second slide" 
        //                 style={{ maxHeight: "min(20vh, 400px)", minHeight: "max(20vh, 200px)", MaxWidth: "min(100vw,1200px)", minWidth: "min(100vw,1200px)" }}/>
        //             </Carousel.Item>
        //             <Carousel.Item>
        //                 <img  src={banner3} alt="Third slide" 
        //                 style={{ maxHeight: "min(20vh, 400px)", minHeight: "max(20vh, 200px)", MaxWidth: "min(100vw,1200px)", minWidth: "min(100vw,1200px)" }}/>
        //             </Carousel.Item>
        //         </Carousel>
        //     </Row>
           
        // </Container>


        <div className="banner-slider">
           
        <Slider {...settings}>
          <div>
            <img src={banner2} alt="Banner 1" />
          </div>
          <div>
            <img src={banner3} alt="Banner 2" />
          </div>
       
        </Slider>
      </div>
    )
}

export default HeaderBody;

 {/*<Row variant="warning" style={{ backgroundColor: "#e80d0d" }} >
                <Col xs={4} sm={4} className="my-auto px-4 mr-n4 margin-tp" style={{ color: "#FFFFFF" }}>
                    <h1 className="pr-5 text-pr">Want to save while eating out? Try CouponBasket.</h1>
                    <p className="pr-5 text-pr">Search for your favourite merchant, cuisine, or dish.</p>
                </Col>
                <Col xs={4} sm={4} className="my-4 py-4 ml-n4 px-4 margin-left" style={{ backgroundColor: "#E2F3DD" }}>
                    <h1>Upto 50% off</h1>
                    <p>*extra 10% cash back</p>
                    <Button className="border-0" style={{ borderRadius: "16px", backgroundColor: "#e80d0d", color: "#FFFFFF" }}>
                        See details <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                </Col>
                <Col xs={4} sm={4} className="my-4 py-4 ml-4 px-4 margin-left" style={{ backgroundColor: "#FDEED1", color: "#122427", borderWidth: "8px !important" }}>
                    <h1>Share the love with your Family</h1>
                    <h5 className="font-weight-normal">more deals comming soon.</h5>
                    <Button className="border-0" style={{ borderRadius: "16px", backgroundColor: "#e80d0d", color: "#FFFFFF" }}>
                        See options <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                </Col>
            </Row>         OLD CODE For future ref*/}