import React from "react";
import { Row, Col, Container } from "react-bootstrap";

//custom sidebar component
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import '../styles/Scrollbar.css';

const WalletCardDeck = ({heading, children }) => {
    return (
        <Container className='mt-1' >
            <SimpleBar style={{ maxHeight: "75vh", overflowX: "hidden" }}>
                <Row>
                    <Col className='mx-auto text-center' xs={12} md={6} lg={5}>
                        {<h2>{heading}</h2>}
                    </Col>
                </Row>
                <Row className="row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 mx-1">
                    {children}
                </Row>
            </SimpleBar>
        </Container>
    )
}

export default WalletCardDeck;