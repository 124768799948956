import React from 'react'
import CartCardDeck from "../components/CartCardDeck";
import CartCard from "../components/CartCard";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

function CartPage({ coupons }) {
    const heading =
        <p>Shopping cart</p>;

  return (
    <Container className="mt-2 mb-2">
        <Row className="align-items-top mb-5">
            <Col className="p-0" md={{ span: 9 }} >
                    <CartCardDeck heading={heading}>
                        {coupons.map(coupon => {
                        return <CartCard key={coupon.couponId} coupon={coupon} />
                        })}
                    </CartCardDeck>
            </Col>
            <Col className="mt-5" md={{ span: 3 }} >
                <Card className="mx-auto mt-2 p-3 border-0" style={{ width: "20rem", background: "#F4F6F6" }}>
                    <Card.Header className='text-center' style={{ background: "#F4F6F6" }}>
                        <Card.Title>Order Summary</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col className='px-0 text-right' md={{ span: 3 }}>
                                Subotal : 
                            </Col>
                            <Col md={{ span: 3 }}>
                                $100
                            </Col>
                        </Row>
                        <Row style={{ color: "#007aff" }}>
                            <Col className='px-0 text-right' md={{ span: 3 }}>
                                Save : 
                            </Col>
                            <Col md={{ span: 3 }}>
                                $10
                            </Col>
                        </Row >
                        <Row style={{ color: "#e80d0d" }}>
                            <Col className='px-0 text-right' md={{ span: 3 }}>
                                Total : 
                            </Col>
                            <Col md={{ span: 3 }}>
                                $90
                            </Col>
                        </Row>
                    </Card.Body>
                    <Button style={{ background: "#e80d0d", color: "#ffffff", borderColor: "#e80d0d" }}>
                        Place Order
                    </Button>
                </Card>
            </Col>
        </Row>
    </Container> 

  )
}

export default CartPage;
