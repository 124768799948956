import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Row, Col, Container, Button, ListGroup, Alert } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import biriyaniTemp from '../components/images/temp/biriyaniSample.jpg';
import { useAuth } from "../provider/authProvider";
import '../styles/CouponDetailsCard.css';

const ClaimsDetailsPage = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_apiUrl;

  const { state } = useLocation();
  const { token } = useAuth(); // to get email
  const email = token;

  const [coupon, setCoupon] = useState(state.coupon || {});
  const [imageUrl] = useState(state.imageUrl || {}); // Initial image as fallback
  const [isClaimed, setIsClaimed] = useState(coupon.isClaimedFlag);
  const [claimID, setClaimID] = useState(coupon.claimID);
  const [claimDetails, setClaimDetails] = useState({});
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    if (claimID) {
      const url = `${apiUrl}/GetClaimDetails?claimID=${encodeURIComponent(claimID)}`;

      axios.get(url)
        .then(response => {
          setClaimDetails(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          if (error.response) {
            if (error.response.status === 404) {
              setAlertMessage({ type: 'danger', text: 'Claim details not found.' });
            } else if (error.response.status === 500) {
              setAlertMessage({ type: 'danger', text: 'Unexpected error while fetching claim details.' });
            } else {
              setAlertMessage({ type: 'danger', text: 'Network response was not ok.' });
            }
          } else {
            setAlertMessage({ type: 'danger', text: error.message });
          }
        });
    }
  }, [claimID]);

  const handleClaim = async () => {
    if (!coupon) {
      console.error('No coupon to claim');
      setAlertMessage({ type: 'danger', text: 'No coupon to claim' });
      return;
    }

    try {
      const url = `${apiUrl}/ClaimCoupon`;

      const response = await axios.post(url, null, {
        params: {
          id: coupon.id,
          email: email
        },
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
        }
      });

      const responseData = response.data;

      if (responseData.success) {
        setAlertMessage({ type: 'success', text: responseData.message });
        setIsClaimed(true);
        setClaimID(responseData.data);

        // to keep state after refresh
        let updatedCoupon = {
          ...coupon,  // keep old data
          isClaimedFlag: true, claimID: responseData.data  // update the values of specific keys
        };
        setCoupon(updatedCoupon);
        navigate(null, { replace: true, state: { coupon: updatedCoupon } });

      } else {
        if (responseData.message === 'Please sign up.') {
          navigate('/Signup');
        } else {
          setAlertMessage({ type: 'danger', text: responseData.message });
        }
      }
    } catch (error) {
      console.error('Error: Coupon could not be claimed:', error);
      if (error.response) {
        if (error.response.status === 404) {
          setAlertMessage({ type: 'danger', text: 'Customer details not found or email mismatch.' });
        } else if (error.response.status === 409) {
          setAlertMessage({ type: 'danger', text: 'Claim wallet updation Error: coupon could not be claimed.' });
        } else if (error.response.status === 500) {
          setAlertMessage({ type: 'danger', text: 'Unexpected error while fetching wallet details.' });
        } else {
          setAlertMessage({ type: 'danger', text: 'Network response was not ok.' });
        }
      } else {
        setAlertMessage({ type: 'danger', text: error.message });
      }
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-top" style={{ height: "100vh" }}>
      <Container className="pt-0 px-0 pt-md-5 px-md-auto">
        <Col className="col col-md-8 mx-auto px-0 px-md-auto">
          <Card className="coupon-details-card" style={{ background: '#FAF9F6' }}>
            <Col className="h-50 px-0 py-0 bg-black">
              <Card.Img className="w-100" variant="top" src={imageUrl} />
              <Card.ImgOverlay className="d-flex justify-content-center align-items-end">
                <div className="rounded-pill mt-0 mb-1 w-50 text-center float-right font-weight-bold"
                  style={{ background: '#ffffff', color: '#000000', borderColor: '#ffffff', fontSize: '18px' }}>{coupon.merchantName}
                </div>
              </Card.ImgOverlay>
            </Col>
            <Card.Body className="pl-0 pr-0 pt-1 pb-0 my-auto">
              <Row className="py-1">
                <Col md={11}>
                  <Card.Title className="my-auto text-center font-weight-bold" style={{ fontSize: "18px" }}>{coupon.couponName}</Card.Title>
                </Col>
              </Row>
              <Row className="mx-2 py-2 my-2 rounded-lg border" style={{ background: '#00000008' }}>
                <Col md={11}>
                  {isClaimed === false &&
                    <p>
                      Ready to be claimed!
                    </p>
                  }
                  {isClaimed === true &&
                    <p>
                      <i>Coupon was claimed on {claimDetails.claimedOn} and is valid till <b>{claimDetails.expiresBy}</b></i>
                    </p>
                  }
                </Col>
              </Row>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <Row className="px-2 mb-2">
                <Col className="mt-1" md={9}>
                  <div className="card-text mt-0 mb-0">
                    <p>
                      <b>How to Claim:</b><br />
                      1. Press the claim button to redeem this coupon. Make sure you are at the store while you press claim. <br />
                      2. Show the counter staff the claimed coupon page. This page will be only available for limited time once you press 'claim now'.<br />
                      3. The store will reduce the you save* amount of this coupon from your bill.<br />
                      <br />
                      <b>Conditions:</b><br />
                      You can only claim while you are the store. In order to claim a coupon you should turn on your GPS to update your current location. This is how we know you are the store which you trying to claim the coupon.<br />
                      You should show the claimed coupon page to the store staff for them verify & redeem the coupon.<br />
                    </p>
                  </div>
                </Col>
              </Row>
            </ListGroup>
            <Card.Footer style={{ fontSize: '14px' }}>
              <Row className="mx-0">
                <Col className="d-flex align-items-center px-0">
                *you save {coupon.inStorePrice - coupon.offerPrice}$ through this coupon
                </Col>
                <Col xs={'auto'} className="d-flex justify-content-center align-items-center">
                  {isClaimed === false &&
                    <Button
                      className="border-0 px-3"
                      style={{ borderRadius: '16px', backgroundColor: '#e80d0d', color: '#ffffff' }}
                      onClick={handleClaim}>
                      Claim Now
                    </Button>
                  }
                  {isClaimed === true &&
                    <Button
                      className="border-0 px-3"
                      style={{ borderRadius: '16px', backgroundColor: '#808080', color: '#ffffff' }}
                      disabled>
                      Claimed ✓
                    </Button>
                  }
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Container>
    </div>
  );
};

export default ClaimsDetailsPage;
