import { useEffect, useCallback, useState, useRef } from 'react';

/**
 * Custom hook to handle user location updates.
 * @param {string} apiUrl - The base URL of the API.
 * @param {string} email - The user's email.
 * @returns {Object} - An object containing any error messages.
 */
const useLocation = (apiUrl, email, onLocationUpdate) => { // Add onLocationUpdate callback
  const [errorMessage, setErrorMessage] = useState("");
  const previousLocation = useRef(null); // Ref to store the previous location

  // Initialize previousLocation from localStorage
  useEffect(() => {
    const storedLocation = localStorage.getItem('previousLocation');
    if (storedLocation) {
      previousLocation.current = JSON.parse(storedLocation);
    }
  }, []);

  /**
   * Function to update the user's location on the server.
   * @param {string} email - The user's email.
   * @param {Object} location - The user's location with latitude and longitude.
   */
  const updateUserLocation = useCallback(async (email, location) => {
    const Url = new URL(`${apiUrl}/UpdateCoordinates`);
    Url.searchParams.append('email', email);
    Url.searchParams.append('latitude', location.latitude);
    Url.searchParams.append('longitude', location.longitude);

    try {
      const response = await fetch(Url.toString(), { method: 'POST' });
      const responseText = await response.text();

      if (!response.ok) {
        console.error('User location could not be updated:', responseText);
      }
    } catch (error) {
      console.error('User location update error:', error);
    }
  }, [apiUrl]);

  /**
   * Function to get the user's current location.
   * If the location has changed, it updates the location on the server.
   */
  const handleGetLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newLocation = { latitude, longitude };
          // Check if the location has changed
          if (!previousLocation.current ||
            previousLocation.current.latitude !== latitude ||
            previousLocation.current.longitude !== longitude) {
            previousLocation.current = newLocation;
            localStorage.setItem('previousLocation', JSON.stringify(newLocation)); // Update localStorage
            updateUserLocation(email, newLocation);
            onLocationUpdate(); // Call the callback function
          }
        },
        (error) => {
          console.error('Error getting location:', error.message);
          setErrorMessage('Location access denied. Please allow location permission to proceed.');
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setErrorMessage('Geolocation is not available on your device.');
    }
  }, [email, updateUserLocation, onLocationUpdate]);

  return { handleGetLocation, errorMessage };
};

export default useLocation;
