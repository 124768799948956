import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container, Nav, Tab, Tabs } from "react-bootstrap";
import ScrollspyNav from "react-scrollspy-nav";
import { getMenuUrl } from "../utils/HelperFunctions";
import MoreInfoModal from "../components/MoreInfoModal";
import {
  horizontalScrollMenuStyle,
  horizontalScrollMenuItemStyle,
} from "../Styles";
import ResponsiveCardDeck from "../components/ResponsiveCardDeck";
import CouponCard from "../components/CouponCard";
import couponsJSON from "../dataSource/coupons.json";
import "../styles/MerchantMenuPage.css";
import dummyimg from "../components/images/temp/idukkigold.jpg";
import hotel from "../components/images/hotel-1.jpg";
import sushi from "../components/images/sushi-logo.png";
import location from "../components/images/location.svg";
import Footer from "../components/Footer";
import DealsCards from "../components/Deals";
import HorizontalCoupens from "../components/HorizontalCoupens";
////////////////pass coupons from other page
const MerchantMenuPage = ({ merchant, coupons }) => {
  const navItems = ["Coupons"];
  const headingId = getMenuUrl(navItems[0]);
  const [key, setKey] = useState("home");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="restaurent-wrapper">
        <MerchantMenuHeader merchant={merchant} />
        <Container>
          <ScrollspyNavbar
            headingId={headingId}
            keyState={key}
            setKeyState={setKey}
            coupons={coupons}
            merchantID={merchant.merchantID}
          />
        </Container>
      </div>
      <Footer />
    </div>
  );
};

const CouponSection = ({ coupons, headingId, MerchantID }) => {
  return (
    <div id={headingId}>
      <h2 className="pt-4 my-0">Coupons</h2>
      {coupons.map(
          (coupon) =>
            coupon.merchantID === MerchantID && (
              <HorizontalCoupens key={coupon.couponID} coupon={coupon} />
            )
        )}
    </div>
  );
};

const MerchantMenuHeader = ({ merchant }) => {
  const bullet = " \u2022 ";

  return (
    <div>
      <Container>
        <div className="menu-card">
          <Row className="align-items-center">
            <Col md={6} >
              <h1 className="mb-0">{merchant.merchantName}</h1>
              <Card.Text className="mb-3">
                {merchant.subType1.join(bullet)}
              </Card.Text>
              <Card.Text>
                {"$$"}
                {bullet}
                {"5KM Away"}
              </Card.Text>
              {/* <p className="mb-1">
                {merchant.address}
                {bullet}
                <strong style={{ color: "#05A357", cursor: "pointer" }}>
                  More info
                </strong>
              </p> */}

              <div className="location card-location rest-location">
                <span>
                  <img src={location} alt="location" className="img-location" />{" "}
                </span>
                {merchant.address}
                <span className="more-info">
                  {" "}
                  {bullet}
                  <strong style={{ color: "#05A357", cursor: "pointer" }}>
                    More info
                  </strong>
                </span>
              </div>

              <MoreInfoModal merchant={merchant} />
            </Col>
            <Col md={3} xs={5}>
              <div className="rest-logo">
                <img src={sushi} alt="hotel-logo" />
              </div>
            </Col>
            <Col md={3} xs={7}>
              <div className="rest-img">
                <img src={hotel} alt="hotel" />
                <div className="view-all-photos">View photos</div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

const ScrollspyNavbar = ({
  headingId,
  keyState,
  setKeyState,
  coupons,
  merchantID,
}) => {
  return (
    <div className="tabbar-wrap">
      <Tabs
        id="controlled-tab-example"
        activeKey={keyState}
        onSelect={(k) => setKeyState(k)}
        className="mb-3"
      >
        <Tab  eventKey="home" title="Deals">
        {/* Deals card is an another layout tried which is said by Anand. Uncomment and use if needed */}
          {/* <DealsCards></DealsCards> */}
          
          {/* <HorizontalCoupens coupons={coupons}/> */}
          

           <CouponSection
            coupons={coupons}
            headingId={headingId}
            MerchantID={merchantID}
          /> 
        </Tab>
        <Tab  eventKey="profile" title="About" className="about-details menu-card">
          <h2 className="about-us-des">About Us</h2>
          <div className="content-container">
            <input type="checkbox" id="toggle" className="toggle-checkbox" />
            <p className="content-text">
              Yamigosushi is a sushi merchant that likely specializes in
              offering a variety of sushi dishes. As with many sushi
              merchants, Yamigosushi probably focuses on fresh ingredients,
              high-quality seafood, and a range of sushi options, including
              rolls, sashimi, and nigiri. The menu might include traditional
              Japanese items as well as contemporary or fusion-style sushi,
              catering to different tastes. Sushi merchants like Yamigosushi
              often emphasize presentation, delivering beautifully arranged
              plates. They may also offer other Japanese cuisine items such as
              tempura, ramen, or donburi. The ambiance is typically designed to
              provide a relaxing and authentic dining experience, which could
              range from minimalist and traditional to modern and vibrant.
            </p>
            <label htmlFor="toggle" className="toggle-label"></label>
          </div>

          <hr></hr>

          <h3>Address</h3>
          <div className="location card-location rest-location">
            <span>
              <img src={location} alt="location" className="img-location" />{" "}
            </span>
            {"1964 Lawrenace Scarborough"}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default MerchantMenuPage;
